/* eslint-disable no-return-assign */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

function SEO({
  title,
  desc,
  banner,
  featured,
  pathname,
  article,
  node,
  isProduct,
  product,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime
          siteMetadata {
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultBanner: banner
            headline
            siteLanguage
            ogLanguage
            author
            orgAuthor
            legalName
            twitter
            facebook
          }
        }
      }
    `,
  );

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      orgAuthor,
      legalName,
      twitter,
      facebook,
    },
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image:
      featured && featured.src
        ? `${siteUrl}${featured.src}`
        : null || `${siteUrl}${defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  };

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Organization',
      name: orgAuthor,
      legalName,
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: orgAuthor,
      legalName,
    },
    copyrightYear: '2020',
    creator: {
      '@type': 'Organization',
      name: orgAuthor,
      legalName,
    },
    publisher: {
      '@type': 'Organization',
      name: orgAuthor,
      legalName,
    },
    datePublished: '2020-05-14T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: '2020',
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    });
  }

  let schemaProduct = null;

  if (isProduct) {
    schemaProduct = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: product.title,
      image: `https://www.dvlpr.io${product.images[0].localFile.childImageSharp.gatsbyImageData.src}`,
      description: product.description,
      url: `https://www.dvlpr.io/product/${product.handle}`,
      brand: 'DVLPR',
      // sku: 123456789,
      // aggregateRating: {
      //   '@type': 'AggregateRating',
      //   ratingValue: '4',
      //   reviewCount: '1,987'
      // },
      offers: {
        '@type': 'Offer',
        priceCurrency: 'USD',
        price: product.variants[0].priceV2.amount,
        availability:
          product.availableForSale === true
            ? 'http://schema.org/InStock'
            : 'http://schema.org/OutOfStock',
      },
    };

    // Push current product into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': product.handle,
        name: product.title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        {canonical && <link rel="canonical" href={seo.url} />}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {isProduct && (
          <script type="application/ld+json">
            {JSON.stringify(schemaProduct)}
          </script>
        )}
        <meta
          name="google-site-verification"
          content="D2f-Ah6nIN_ay6mYiwT2zwCd2zub0aUedXGxlhg330U"
        />
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
        isProduct={isProduct}
        product={product}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  featured: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  product: PropTypes.object,
  node: PropTypes.object,
  isProduct: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  featured: null,
  pathname: null,
  article: false,
  product: null,
  node: null,
  isProduct: false,
};

export default SEO;
