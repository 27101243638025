import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

const Facebook = ({
  url,
  name,
  type,
  title,
  desc,
  image,
  locale,
  isProduct,
  product,
}) => {
  let googleProductCategory;

  if (isProduct) {
    switch (product.productType) {
      case 'Hats':
        googleProductCategory = '173';
        break;
      case 'Enamel Pin':
        googleProductCategory = '4179';
        break;
      case 'Stickers':
        googleProductCategory = '4054';
        break;
      case 'T-shirts':
        googleProductCategory = '212';
        break;
      default:
        googleProductCategory = '212';
        break;
    }
  }
  return <>
    {isProduct ? (
      <Helmet>
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.description} />
        <meta
          property="og:url"
          content={`https://www.dvlpr.io/product/${product.handle}`}
        />
        <meta
          property="og:image"
          content={`https://www.dvlpr.io${product.images[0].localFile.childImageSharp.gatsbyImageData.src}`}
        />
        <meta property="product:brand" content="DVLPR" />
        <meta
          property="product:availability"
          content={
            product.availableForSale === true ? 'in stock' : 'out of stock'
          }
        />
        <meta property="product:condition" content="new" />
        <meta
          property="product:price:amount"
          content={product.variants[0].priceV2.amount}
        />
        <meta property="product:price:currency" content="USD" />
        <meta property="product:retailer_item_id" content={product.id} />
        <meta property="product:category" content={googleProductCategory} />
        {/* <meta property="product:item_group_id" content="fb_tshirts" /> */}
      </Helmet>
    ) : (
      <Helmet>
        {name && <meta property="og:site_name" content={name} />}
        <meta property="og:locale" content={locale} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={image} />
        <meta property="og:image:alt" content={desc} />
      </Helmet>
    )}
  </>;
};

export default Facebook;

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
};

Facebook.defaultProps = {
  type: 'website',
  name: null,
};
