/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { jsx, Flex, Footer, Box, Container } from 'theme-ui';

export default function FooterWrapper() {
  return (
    <Footer
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        fontFamily: 'body',
        justifyContent: ['space-around', 'space-between'],
        position: ['relative'],
        bottom: '0px',
        left: '0px',
        right: '0px',
        zIndex: '2000',
        py: 3,
        px: [3, 4],
        flexWrap: ['wrap', 'nowrap'],
        backgroundColor: ['background', 'transparent'],
      }}
    >
      <div sx={{ marginBottom: [3, 0] }}>
        <a
          href="https://www.instagram.com/dvlpr/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            variant: 'styles.navlink',
          }}
        >
          instagram
        </a>
        <a
          href="https://twitter.com/dvlpr"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            variant: 'styles.navlink',
          }}
        >
          twitter
        </a>
      </div>
      <div>
        <p sx={{ variant: 'styles.navlink' }}>
          © 2014 - {new Date().getFullYear()}, DVLPR®
        </p>
      </div>
    </Footer>
  );
}
