/* eslint-disable jsx-a11y/no-distracting-elements */
/** @jsx jsx */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { jsx, Main, Container, useColorMode } from 'theme-ui';
import { Global } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Footer from './Footer';
import SEO from './seo/seo';
import 'normalize.css';
import Header from './Header';

const Layout = ({ children, customSEO }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [colorMode, setColorMode] = useColorMode();

  return (
    <>
      <Global
        styles={{
          '*': {
            boxSizing: 'border-box',
            fontFeatureSettings: ["'liga' '1'", "'ss01' 1"],
          },
          body: {
            margin: 0,
            fontFamily: 'Inter, sans-serif',
            backgroundColor: 'colors.background',
            // cursor: `url(${cursor}), auto`,
          },
          button: {
            cursor: 'pointer',
          },
        }}
      />
      <Header />

      <div
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container sx={{ mt: 6, flex: 1 }}>
          {!customSEO && <SEO />}
          <Main>{children}</Main>
        </Container>
        {/* <button
          sx={{
            position: 'fixed',
            right: '-20px',
            top: '50%',
            background: 'none',
            border: 'none',
            transform: 'rotate(90deg)',
            textTransform: 'uppercase',
            color: 'text',
            fontSize: [3, 3, '2.2vw'],
            lineHeight: ['20px', '20px', '3vw'],
          }}
          onClick={() => {
            const next = colorMode === 'dark' ? 'light' : 'dark';
            setColorMode(next);
          }}
          type="button"
        >
          {colorMode}
        </button> */}
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  customSEO: PropTypes.bool,
};

Layout.defaultProps = {
  customSEO: false,
};

export default Layout;
